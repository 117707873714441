const calculateGDS = (
  annualIncome,
  propertyTaxes,
  monthlyHeating,
  allMortgageMonthlyPayment,
  loanAmount,
  interestRate,
  loanTerm,
  condoFees,
  repaymentType
) => {
  const totalMonthlyIncome = Number(annualIncome) / 12;

  let mortgageMonthlyPayment = null;

  if (repaymentType === 'interest_only') {
    mortgageMonthlyPayment = calculateMortgagePayment(parseFloat(interestRate) / 100, loanAmount);
  } else {
    mortgageMonthlyPayment = calculateMortgagePayment(
      parseFloat(interestRate) / 100,
      loanAmount,
      loanTerm
    );
  }

  const propertyTaxesCalculate = Number(propertyTaxes) / 12;

  const totalHouseMonthlyPayments =
    propertyTaxesCalculate +
    Number(monthlyHeating) +
    Number(condoFees) +
    allMortgageMonthlyPayment +
    mortgageMonthlyPayment;

  const calculatedGDS = (totalHouseMonthlyPayments / totalMonthlyIncome) * 100;

  return calculatedGDS.toFixed(2);
};

const calculateTDS = (
  annualIncome,
  propertyTaxes,
  monthlyHeating,
  allMortgageMonthlyPayment,
  liabilityMonthlyPayment,
  loanAmount,
  interestRate,
  loanTerm,
  condoFees,
  repaymentType
) => {
  const totalMonthlyIncome = Number(annualIncome) / 12;

  let mortgageMonthlyPayment = null;

  if (repaymentType === 'interest_only') {
    mortgageMonthlyPayment = calculateMortgagePayment(parseFloat(interestRate) / 100, loanAmount);
  } else {
    mortgageMonthlyPayment = calculateMortgagePayment(
      parseFloat(interestRate) / 100,
      loanAmount,
      loanTerm
    );
  }

  const propertyTaxesCalculate = Number(propertyTaxes) / 12;

  const totalHouseMonthlyPayments =
    propertyTaxesCalculate +
    Number(monthlyHeating) +
    Number(condoFees) +
    allMortgageMonthlyPayment +
    mortgageMonthlyPayment;

  const totalMonthlyPayment = totalHouseMonthlyPayments + liabilityMonthlyPayment;

  const calculatedTDS = (totalMonthlyPayment / totalMonthlyIncome) * 100;

  return calculatedTDS.toFixed(2);
};

const calculateMortgagePayment = (annualInterestRate, loanAmount, amortizationYears) => {
  let monthlyPayment = 0;

  if (amortizationYears) {
    const effectiveRate = Math.pow(1 + annualInterestRate / 2, 2) - 1;

    const monthlyEffectiveRate = Math.pow(1 + effectiveRate, 1 / 12) - 1;
    monthlyPayment =
      (monthlyEffectiveRate * loanAmount) /
      (1 - Math.pow(1 + monthlyEffectiveRate, -amortizationYears * 12));
  } else {
    const monthlyEffectiveRate = annualInterestRate / 12;
    monthlyPayment = monthlyEffectiveRate * loanAmount;
  }

  return Math.round(monthlyPayment * 100) / 100;
};

const calculateGDSandTDSInMortgage = (
  currentEmp,
  OtherInc,
  liabilityData,
  Id,
  formData,
  existingMortgages
) => {
  const opportunityData = { ...formData };
  const currentIncome = currentEmp?.length ? currentEmp[0].annualIncome : 0;
  const otherIncome = OtherInc.reduce((n, { annualIncome }) => n + Number(annualIncome), 0);
  const annualIncome = Number(currentIncome) + Number(otherIncome);
  const propertyTaxes =
    opportunityData && opportunityData.oppPropertyTaxes ? opportunityData.oppPropertyTaxes : 0;
  const monthlyHeating =
    opportunityData && opportunityData.oppMonthlyHeating ? opportunityData.oppMonthlyHeating : 0;
  const loanAmount =
    opportunityData && opportunityData.oppRequestedAmount ? opportunityData.oppRequestedAmount : 0;
  const condoFees = opportunityData && opportunityData.condoFees ? opportunityData.condoFees : 0;
  const interestRate =
    opportunityData && opportunityData.oppInterestRate ? opportunityData.oppInterestRate : 0;
  const loanTerm =
    opportunityData && opportunityData.oppAmortizationTerm
      ? opportunityData.oppAmortizationTerm
      : 0;
  const repaymentType =
    opportunityData && opportunityData.repaymentType ? opportunityData.repaymentType : null;

  let allMortgageMonthlyPayment = 0;
  existingMortgages.forEach((mortgage) => {
    if (mortgage && !mortgage.isMortgagePaidOff) {
      allMortgageMonthlyPayment =
        allMortgageMonthlyPayment + Number(mortgage.mortgageMonthlyPayment);
    }
  });
  // if (allMortgageMonthlyPayment === 0) {
  //   return { updatedGds: null, updatedTDS: null };
  // }

  const updatedGds = calculateGDS(
    annualIncome,
    propertyTaxes,
    monthlyHeating,
    allMortgageMonthlyPayment,
    loanAmount,
    interestRate,
    loanTerm,
    condoFees,
    repaymentType
  );
  let liabilityMonthlyPayment = liabilityData.reduce(
    (n, { monthlyPayment }) => n + Number(monthlyPayment),
    0
  );
  const updatedTDS = calculateTDS(
    annualIncome,
    propertyTaxes,
    monthlyHeating,
    allMortgageMonthlyPayment,
    liabilityMonthlyPayment,
    loanAmount,
    interestRate,
    loanTerm,
    condoFees,
    repaymentType
  );

  return { updatedGds, updatedTDS };
};

const getLocationAddress = (property) => {
  const { oppStreetAddress, oppUnitNumber } = property;

  let location = '';

  if (oppUnitNumber) {
    location = `${oppUnitNumber}-`;
  }

  if (oppStreetAddress) {
    location = `${location}${oppStreetAddress}`;
  }

  return location;
};

export {
  calculateGDS,
  calculateTDS,
  calculateGDSandTDSInMortgage,
  calculateMortgagePayment,
  getLocationAddress
};
