import { property } from 'lodash';
import * as Yup from 'yup';

const oppPropertyInfoInitValue = {
  filogixId: null,
  //general location
  oppCountry: 'Canada',
  oppProvince: '',
  oppArea: '',
  //location
  oppStreetAddress: '',
  oppUnitNumber: '',
  oppCity: '',
  oppPostalCode: '',
  //property details
  oppYearBuilt: null,
  oppPropertyType: '',
  oppTenure: '',
  oppOccupancy: '',
  oppDescription: '',
  //value
  oppOriginalPurchasePrice: '',
  oppIsAppraisalCompleted: '',
  oppAppraisalEstimatedValue: '',
  oppAppraisalEstimatedDate: '',
  appraisalFile: '',
  oppAppraisalValue: '',
  oppAppraisalDate: '',

  //costs
  oppPropertyTaxes: '',
  oppMonthlyHeating: '100',
  condoFees: '',
  //property images
  oppPropertyImage: '',
  showPropertyImage: false,
  latitude: '',
  longitude: '',
  streetStaticImage: ''
};

const oppPropertyInfoValidation = {
  //general location
  oppCountry: Yup.string().nullable().trim().required('Required'),
  oppProvince: Yup.string().nullable().trim().required('Required'),
  oppArea: Yup.string().nullable().trim().required('Required'),
  //location
  oppStreetAddress: Yup.string().nullable().trim().required('Required'),
  oppUnitNumber: Yup.string().nullable().trim().max(10, 'Enter valid unit number'),
  oppCity: Yup.string().nullable().trim().max(25, 'Enter valid city').required('Required'),
  oppPostalCode: Yup.string()
    .nullable()
    .min(7, 'Enter valid postal code')
    .trim()
    .required('Required'),
  //property details
  oppYearBuilt: Yup.number()
    .nullable()
    .min(1000, 'Please enter year')
    .max(new Date().getFullYear(), 'Enter valid year'),
  oppPropertyType: Yup.string().nullable().trim().required('Required'),
  oppTenure: Yup.string().nullable().trim().required('Required'),
  oppOccupancy: Yup.string().nullable().trim().required('Required'),
  oppDescription: Yup.string().nullable().trim().max(2048, 'Enter valid postal code'),
  //value
  oppOriginalPurchasePrice: Yup.number().nullable().max(999999999999999, 'Price is not valid'),
  oppIsAppraisalCompleted: Yup.boolean().nullable().required('Required'),
  //If oppIsAppraisalCompleted is false
  oppAppraisalEstimatedValue: Yup.number()
    .nullable()
    .when('oppIsAppraisalCompleted', (isCom, schema) => {
      if (!isCom) {
        return schema
          .required('Required')
          .min(0, 'Required')
          .max(999999999999999, 'Price is not valid');
      }
      return schema;
    }),
  oppAppraisalEstimatedDate: Yup.string()
    .nullable()
    .when('oppIsAppraisalCompleted', (isCom, schema) => {
      if (!isCom) {
        return schema.trim().required('Required');
      }
      return schema;
    }),
  //If oppIsAppraisalCompleted is true
  oppAppraisalValue: Yup.number()
    .nullable()
    .when('oppIsAppraisalCompleted', (isCom, schema) => {
      if (isCom) {
        return schema
          .required('Required')
          .min(0, 'Required')
          .max(999999999999999, 'Price is not valid');
      }
      return schema;
    }),
  oppAppraisalDate: Yup.string()
    .nullable()
    .trim()
    .when('oppIsAppraisalCompleted', (isCom, schema) => {
      if (isCom) {
        return schema.trim().required('Required');
      }
      return schema;
    }),
  appraisalFile: Yup.string()
    .nullable()
    .trim()
    .when('oppIsAppraisalCompleted', (isCom, schema) => {
      if (isCom) {
        return schema.trim().required('Required');
      }
      return schema;
    }),

  //costs
  oppPropertyTaxes: Yup.number()
    .nullable()
    .required('Required')
    .min(0, 'Required')
    .max(999999999999999, 'Price is not valid'),
  oppMonthlyHeating: Yup.number()
    .nullable()
    .required('Required')
    .min(0, 'Required')
    .max(999999999999999, 'Price is not valid'),
  condoFees: Yup.number()
    .nullable()
    .max(999999999999999, 'Price is not valid')
    .when('oppTenure', {
      is: 'Condo',
      then: () =>
        Yup.number().nullable().max(999999999999999, 'Price is not valid').required('Required'),
      otherwise: () => Yup.number().nullable().max(999999999999999, 'Price is not valid')
    }),
  //property images
  oppPropertyImage: Yup.string().nullable().trim(),
  showPropertyImage: Yup.string().nullable()
};

const propertyTypesOptions = [
  {
    key: 'Apartment High Rise',
    property: 'Apartment High Rise'
  },
  {
    key: 'Apartment Low Rise',
    property: 'Apartment Low Rise'
  },
  {
    key: 'Detached',
    property: 'Detached'
  },
  {
    key: 'Duplex Detached',
    property: 'Duplex - Detached'
  },
  {
    key: 'Duplex Semi Detached',
    property: 'Duplex - Semi-Detached'
  },
  {
    key: 'Four Plex Detached',
    property: 'Four Plex - Detached'
  },
  {
    key: 'Four Plex Semi Detached',
    property: 'Four Plex - Semi-Detached'
  },
  {
    key: 'Mobile',
    property: 'Mobile'
  },
  {
    key: 'Modular Home Detached',
    property: 'Modular Home - Detached'
  },
  {
    key: 'Modular Home Semi Detached',
    property: 'Modular Home - Semi-Detached'
  },
  {
    key: 'Row Housing',
    property: 'Row Housing'
  },
  {
    key: 'Semi Detached',
    property: 'Semi-Detached'
  },
  {
    key: 'Stacked',
    property: 'Stacked'
  },
  {
    key: 'Tri Plex Detached',
    property: 'Tri Plex - Detached'
  },
  {
    key: 'Tri Plex Semi Detached',
    property: 'Tri Plex - Semi-Detached'
  }
];

const tenureTypeOptions = ['Freehold', 'Leasehold', 'Condo', 'Co-op'];
const occupiedOptions = [
  'Owner Occupied',
  'Rental',
  'Owner Occupied & Tenant',
  'Second Home',
  'Vacant'
];

const imageUrlToObj = async (url) => {
  const fileName = 'street-image.jpg';
  try {
    const newFileGen = await fetch(url).then(async (response) => {
      const contentType = response.headers.get('content-type');
      const blob = await response.blob();
      const file = new File([blob], fileName, { contentType, type: 'image/jpeg' });
      // access file here
      return file;
    });
    return newFileGen;
  } catch (error) {
    console.log(error);
  }
};

export {
  oppPropertyInfoInitValue,
  oppPropertyInfoValidation,
  // estimatedAppraisalValidation,
  // appraisalValidation,
  propertyTypesOptions,
  tenureTypeOptions,
  occupiedOptions,
  imageUrlToObj
};
